import { render, staticRenderFns } from "./DrillInformation.vue?vue&type=template&id=0cb7dc82&scoped=true&lang=pug&"
import script from "./DrillInformation.vue?vue&type=script&lang=ts&"
export * from "./DrillInformation.vue?vue&type=script&lang=ts&"
import style0 from "./DrillInformation.vue?vue&type=style&index=0&id=0cb7dc82&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cb7dc82",
  null
  
)

export default component.exports