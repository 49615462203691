



























import { Component, Prop, Mixins, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DrillWebApi from '@/mixins/drills/WebApi'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class DrillInformation extends Mixins(DrillWebApi) {
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  @Prop()
  drillInformation!: any

  @Prop()
  classCategoryCode!: string

  get classObject(): { [key: string]: boolean } {
    const status = this.drillInformation?.status
    return {
      'box__frame-clear': status === 'clear',
      'box__frame-active': status !== 'clear',
    }
  }

  get buttonColorType(): string {
    const status = this.drillInformation?.status
    let color = 'white'
    switch (status) {
      case 'clear':
        color = 'gray'
        break
      case 'start':
        color = 'skyblue'
        break
      case 'homework':
        color = 'pink'
        break
      case 'reconfirm':
        color = 'lightblue'
        break
    }
    return color
  }

  get statusString(): string {
    const status = this.drillInformation?.status
    let str = ''
    switch (status) {
      case 'clear':
        str = 'クリア！'
        break
      case 'start':
        str = '始める'
        break
      case 'homework':
        str = '宿題を出そう'
        break
      case 'reconfirm':
        str = '間違い直し'
        break
    }
    return str
  }

  get isNoMistake(): boolean {
    return this.drillInformation?.status === 'noMistake'
  }

  public async startDrill(classCategoryCode: string): Promise<any> {
    Vue.prototype.$loading.start()
    Vue.prototype.$logger.info('-- DrillInformation startDrill')

    const status = this.drillInformation?.status
    const resultDrillId = 1

    switch (status) {
      case 'clear':
        // 何もしない
        break
      case 'start':
      case 'reconfirm': {
        // ドリル（確認テストまたは宿題）を開始する。「始める」と「間違い直し」共通
        const startDrill = await this.startDrillApi({
          classModeCode: this.classMode(),
          classCategoryCode: classCategoryCode,
          subjectCode: this.subjectCode,
          lessonId: this.drillInformation.lessonId,
        })

        if (status === 'start') {
          // ドリル（確認テスト）開始APIの結果を受けて問題ページへ遷移する
          // 途中で抜けていた場合はそのpageへ遷移
          this.$router.push({
            name: 'drill',
            query: {
              resultDrillId: String(startDrill.resultDrillId),
              pageType: startDrill.pageType,
              page: String(startDrill.page || ''),
              curriculumSUnitId: '', // 小項目IDは宿題、確認テストではなし
              classCategoryCode: classCategoryCode, // 授業種別
            },
          })
        } else {
          // ドリル（確認テスト）開始APIの結果を受けて結果ページ遷移する
          // 一度解いている場合に遷移
          this.$router.push({
            name: 'drillResult',
            query: {
              resultDrillId: String(startDrill.resultDrillId),
              pageType: startDrill.pageType,
              page: String(startDrill.page || ''),
              curriculumSUnitId: '', // 小項目IDは宿題、確認テストではなし
              classCategoryCode: classCategoryCode, // 授業種別
            },
          })
        }
        break
      }
      case 'homework':
        // 宿題のページに遷移
        window.location.href = '/student/confirmation/SY'
        // TODO 宿題を出そうで宿題に遷移したときに同じボタンのリンクが更新されないため保留
        // this.$router.push('/student/confirmation/SY')
        break
    }
    Vue.prototype.$loading.complete()
  }
}
