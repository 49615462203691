




























import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import DrillInformation from '@/components/organisms/DrillInformation.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    ButtonBase,
    SidebarSwitcher,
    DrillInformation,
    StudentBottomPanel,
  },
})

/**
 *  Mixins 授業中かどうかを判別して、WebSocket通信を開始する処理をマージ
 *         授業中の場合は授業IDやWebSocket通信中のチャンネルをstoreに保持する
 */
export default class DrillConfirmation extends Mixins(LessonStudent, ClassModeChangeAble) {
  private colorType = 'gray'

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private subjectCodes = [
    { text: '国語', code: 'ko' },
    { text: '数学', code: 'su' },
    { text: '英語', code: 'ei' },
    { text: '理科', code: 'ri' },
    { text: '社会', code: 'sh' },
  ]

  // Web APIのレスポンスパラメータのタイプ
  private drillData: {
    lessonId: number
    lessonClassName: string
    status: string
    curriculumSUnitNames: string[]
    duration: number
    correctCount: number
    questionCount: number
  }[] = []

  public async mounted(): Promise<void> {
    Vue.prototype.$logger.log(`-- DrillConfirmation mounted`)

    await this.checkClassModeAndSideMenuMode()

    // 確認テスト一覧取得
    await this.loadDatas()

    await this.noticeGroup()

    await this.getExistSupporterCallHistory()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- DrillConfirmation beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private get subjectName() {
    return this.subjectCodes.find((subject) => subject.code === this.subjectCode)?.text
  }

  private get subjectLink() {
    return `/student/subjects/${this.$route.params.classCategoryCode}`
  }

  private get noticeCurrentPageText() {
    let result = ''

    if (this.$route.params.classCategoryCode === 'KK') {
      result = '確認テスト選択中'
    } else if (this.$route.params.classCategoryCode === 'SY') {
      result = '宿題選択中'
    }

    return result
  }

  private noticePageTitle = ''

  /**
   * 確認テスト一覧取得APIを実行しデータをセットする
   */
  private async loadDatas(): Promise<void> {
    Vue.prototype.$logger.log(`-- DrillConfirmation loadDatas`)

    const params = [
      `classModeCode=${this.classMode()}`,
      `classCategoryCode=${this.$route.params.classCategoryCode}`,
      `subjectCode=${this.subjectCode}`,
    ]

    try {
      // 宿題、確認テスト取得API
      const result = await Vue.prototype.$http.httpWithToken.get(`/drill/list?${params.join('&')}`)
      this.drillData = result.data
    } catch {
      // TODO エラー処理
      throw 'Drill Error!'
    }
  }

  private callSupporterClicked() {
    if (this.colorType === 'gray') {
      this.colorType = 'darkgray'
    }
    this.callSupporter(true)
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'darkgray' : 'gray'
      } catch (e) {
        Vue.prototype.$logger.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  @Watch('lessonId')
  onLessonIdChanged() {
    this.getExistSupporterCallHistory()
  }
}
